import { useGameInitStore } from '@st/casino/stores/useGameInitStore'

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (import.meta.server) return undefined

  const { history } = useNavigationHistory()

  const isDirectNavigation = history.value.length <= 1
  const isNavigationWithinGamePage =
    !isDirectNavigation && to.path === from.path

  const gameId = Number(to.params.id)
  const gameMode = to.query.mode === 'demo' ? 'demo' : 'real'

  const { countryCode, ip } = useGeoAvailability()
  const gameInitStore = useGameInitStore()

  const { launchGameWithStates } = gameInitStore
  const { gameMode: previousGameMode } = storeToRefs(gameInitStore)

  function navigateToModal(name: string, props: Record<string, unknown> = {}) {
    return navigateTo({
      query: {
        ...from.query,
        modal: name,
        ...props,
      },
      /**
       * Если игрок переходит на игру из каталога, мы можем просто открыть модалку поверх текущей страницы.
       * Но если игрок пришел по прямой ссылке, на фоне пустая страница игры - не очень.
       * Поэтому редиректим в казино и там показываем модалку ошибки
       */
      ...(isDirectNavigation ? { path: '/casino' } : {}),
    })
  }

  /**
   * Это мы переходим по ссылкам внутри страницы игры (например открываем/закрываем модалки и тд)
   * при этом не меняя режим игры. В таком случае просто ничего не делаем и не проверяем
   */
  if (isNavigationWithinGamePage && gameMode === previousGameMode.value)
    return undefined

  const { result, error } = await launchGameWithStates({ gameId, gameMode })

  switch (result) {
    case 'success': {
      return undefined
    }
    case 'authError': {
      return navigateToModal('login')
    }
    case 'authErrorWithDemoAvailable': {
      /**
       * Это кейс когда мы переходим из демо режима в реальную игру
       */

      if (isNavigationWithinGamePage) {
        if (to.query.modal) {
          await navigateToModal(String(to.query.modal))
        } else {
          return navigateToModal('login')
        }
      }

      return navigateTo({
        path: to.path,
        query: {
          ...to.query,
          mode: 'demo',
        },
      })
    }
    case 'geoErrorWithDemoAvaiable': {
      if (isNavigationWithinGamePage)
        return navigateToModal('restriction', {
          countryCode: countryCode.value,
          ip: ip.value,
        })

      return navigateTo({
        path: to.path,
        query: {
          ...to.query,
          mode: 'demo',
        },
      })
    }
    case 'geoError': {
      return navigateToModal('restriction', {
        countryCode: countryCode.value,
        ip: ip.value,
      })
    }
    case 'initError': {
      const code = error?.error
      if (
        code === 'FORBIDDEN_COUNTRY' ||
        code === 'CASINO_GAME_INIT_FORBIDDEN_BY_COUNTRY'
      ) {
        return navigateToModal('gameGeoError')
      }
      return navigateToModal('gameError', { code })
    }
    default: {
      return undefined
    }
  }
})
